/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-08 21:38:04
 * @Module: 收银台
 */
 <template>
  <div class="cashier">
    <div class="breadcrumb ">
      <span class="pointer" @click="$router.push({name:'expenseCenter'})">费用中心</span>
      <span class="el-icon-arrow-right" style="margin:0 14px;"></span>
      <span style="color: #262626;">选择充值</span>
    </div>
    <div class="tabs border">
      <div class="tabs-item pointer" :class="{active:$route.name=='gold'}" @click="$store.commit('showToastDialog', true)">
        金币充值
      </div>
      <div class="tabs-item pointer" :class="{active:$route.name=='coupon'}" @click="$store.commit('showToastDialog', true)">
        卡券充值
      </div>
      <div class="solid " :class="$route.name=='gold'?'solid-ac1':'solid-ac2'"></div>
    </div>
    <router-view />
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.cashier {
  margin-top: -14px;
  .border {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    background-color: #fff;
  }
  .breadcrumb {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 50px;
  }
  .tabs {
    position: relative;
    display: flex;
    border-bottom: 1px solid #eee;
    &-item {
      width: 104px;
      line-height: 56px;
      text-align: center;
    }
    .solid {
      width: 30px;
      height: 2px;
      background: #0062ff;
      border-radius: 2px;
      position: absolute;
      bottom: -1px;
      transition: 0.5s all;
      &-ac1 {
        left: 37px;
      }
      &-ac2 {
        left: 141px;
      }
    }
    .active {
      color: #0062ff;
    }
  }
}
</style>