<template>
  <div class="home newHome">
    <!-- <authorizedUser /> -->
    <el-row :gutter="16">
      <el-col :span="16">
        <el-row :gutter="16">
          <el-col :span="12">
            <!-- 面板 -->
            <div class="panel border">
              <div class="static " :class="name ? 'success' : 'error'"></div>
              <div class="panel-top">
                <el-avatar :size="58"></el-avatar>
                <div class="panel-top-right">
                  <div class="username">
                    <p>
                      {{ name || '暂无信息' }}
                    </p>
                    <div class="icon" :class="name ? 'success' : 'error'"></div>
                  </div>
                  <div class="phone">账号：{{ user.phone }}</div>
                </div>
              </div>
              <div class="panel-bottom">
                <div class="cell" v-for="(item, index) in cellList" :key="index">
                  <div class="cell-l">
                    <div class="icon" :class="'icon-' + index"></div>
                    <div class="title">{{ item.title }}</div>
                  </div>
                  <div class="cell-r pointer" @click="$router.push(item.path)">
                    <span>{{ item.ext }}</span>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="electronicDocuments border">
              <div class="newHome-title">
                <p>电子合同</p>
                <span @click="$router.push('/electronicDocuments')">查看更多</span>
              </div>
              <div class="electronicDocuments-content">
                <div class="item">
                  <div class="value">{{ contractProfile.inProgressCount }}</div>
                  <div class="label">签署中</div>
                </div>
                <div class="solid"></div>
                <div class="item">
                  <div class="value">{{ contractProfile.finishedCount }}</div>
                  <div class="label">签署完成</div>
                </div>
                <div class="solid"></div>
                <div class="item">
                  <div class="value">{{ contractProfile.all }}</div>
                  <div class="label">发送总数</div>
                </div>
                <div class="solid"></div>
                <div class="item">
                  <div class="value">{{ contractProfile.nearDeadlineCount }}</div>
                  <div class="label">即将截止</div>
                </div>
              </div>
            </div>
            <div class="expenseCenter border" style="margin-top:16px;">
              <div class="newHome-title">
                <p>费用中心</p>
                <span @click="$router.push('/expenseCenter')">查看更多</span>
              </div>
              <div class="expenseCenter-content">
                <div class="item">
                  <div class="top">
                    <div class="label">
                      <p>账户金币</p>
                      <img src="@/static/image/icon_jinbi.png" alt="" srcset="">
                    </div>
                    <p class="btn" @click="$store.commit('showToastDialog', true)">点击充值</p>
                  </div>
                  <div class="value">{{ balance | priceFormat }}</div>
                </div>
                <div class="item" style="margin-top:16px;">
                  <div class="top">
                    <div class="label">
                      <p>账户卡券</p>
                      <img src="@/static/image/icon_dianquan.png" alt="" srcset="">
                    </div>
                    <p class="btn" @click="$store.commit('showToastDialog', true)">点击充值</p>
                  </div>
                  <div class="value" style="color: #0062FF;">{{ beans | priceFormat }}</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- <div style="margin-top:16px;">
          <el-carousel height="160px" class="border">
            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
              <el-image :src="$oss+item.cover" @click.native="()=>bannerOpen(item.url)" fit="cover" style="width: 100%; height: 160px"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div> -->
        <div class="archiveManagement border" style="margin-top:16px">
          <div class="newHome-title">
            <p>最近文件</p>
            <span @click="$router.push('/electronicDocuments')">查看更多</span>
          </div>
          <ArchiveManagementDetail :showSelection="false" />
        </div>
      </el-col>
      <el-col :span="8">
        <div class="notice border">
          <div class="newHome-title">
            <p>平台通知</p>
            <span @click="$router.push('/notice')">查看更多</span>
          </div>
          <div style="height:1px;margin:0 20px;background:#eee;"></div>
          <div class="notice-content">
            <div class="item pointer" v-for="item in noticeList.slice(0, 4)" :key="item.noticeId"
              @click="$router.push({ path: `/notice/${item.noticeId}` })">
              <div class="item-t">
                <div class="item-t-l">
                  <i class="el-icon-ali-dian1"></i>
                  <p>{{ item.title }}</p>
                  <div class="icon" v-if="item.top">顶置</div>
                </div>
                <p class="time">{{ item.createdAt | timeFormat('mm-dd') }}</p>
              </div>
              <div class="item-b">{{ item.content | pre }}</div>
            </div>
          </div>
        </div>
        <div class="exposure border" style="margin-top:16px;">
          <div class="newHome-title">
            <p>违约主播</p>
            <span @click="$router.push('/exposure')">查看更多</span>
          </div>
          <div style="height:1px;margin:0 20px;background:#eee;"></div>
          <div class="exposure-content">
            <div class="item " v-for="item in exposureList" :key="item.uuid">
              <div class="item-l">
                <div class="time">{{ item.updatedAt | timeFormat }}</div>
                <div class="name">{{ item.name }}</div>
              </div>
              <div class="idNumber">{{ item.idNumber }}</div>
            </div>
            <div class="mask"></div>
          </div>
        </div>
      </el-col>
    </el-row>
    <ad_1 />
    <inform />

  </div>
</template>

<script>
import { mapState } from "vuex";
import ad_1 from "@/components/Ad/ad_1.vue"
import inform from "@/components/inform.vue";
import ArchiveManagementDetail from "@/views/ArchiveManagement/ArchiveManagementDetail.vue";

// import authorizedUser from "@/components/authorizedUser.vue"
export default {
  components: {
    ad_1,
    inform,
    ArchiveManagementDetail,
    // authorizedUser
  },
  name: 'Home',
  data() {
    return {
      cellList: [{
        title: '发起签署',
        ext: '点击发起',
        path: '/electronicDocuments/electronicDocumentsAdd'
      },
      {
        title: '精准取证',
        ext: '取证方式',
        path: '/accurateEvidence'
      },
      {
        title: '三要素核查',
        ext: '立即查询',
        path: '/inspect/inspectSearch'
      },
        // {
        //   title: '查询小号',
        //   ext: '查询小号',
        //   path: '/search/searchForm'
        // }
      ],
      bannerList: [],
      noticeList: [],
      InstituteJusticeList: [],
      evidenceLatestList: [],
      contractProfile: {
        all: 0,
        finishedCount: 0,
        inProgressCount: 0,
        nearDeadlineCount: 0,
      },
      exposureList: [],
    }
  },
  computed: {
    ...mapState({
      balance: state => state.user.balance,
      beans: state => state.user.beans,
      company: state => state.user.user.company,
      name: state => state.user.user.name,
      user: state => state.user.user
    })
  },
  filters: {
    pre(text) {
      return text.replace(/<[^<]+?>/g, '') || "[图片]"
    }
  },
  mounted() {
    this.getNotice();
    this.getInstituteJustice();
    this.getBannerList();
    this.getContractProfile();
    this.$store.dispatch('user/getCompanyBalance');
    this.getEvidenceLatestList();
    this.getExposureList()
    this.$store.commit('showToastDialog',true);
  },
  methods: {
    toAccountAuth() {
      this.$store.dispatch('user/toAccountAuth')
    },
    toAccountCompanyAuth() {
      this.$store.dispatch('user/toAccountCompanyAuth')
    },
    async getNotice() {
      const { status, data } = await this.$api.getNotice({ current: 1, size: 3 })
      if (status === 200) {
        this.noticeList = data.records
      }
    },
    async getInstituteJustice(current = 1, size = 4) {
      const { status, data } = await this.$api.getVideo({ current, size })
      if (status === 200) {
        const { records } = data
        this.InstituteJusticeList = records
      }
    },
    async getBannerList() {
      const { status, data } = await this.$api.getBannerList({ label: 'home' })
      if (status === 200) {
        this.bannerList = data
      }
    },
    async getContractProfile() {
      const { status, data } = await this.$api.getContractProfile()
      if (status === 200) {
        this.contractProfile = data
      }
    },
    async getEvidenceLatestList() {
      const { status, data } = await this.$api.getEvidenceLatestList()
      if (status === 200) {
        this.evidenceLatestList = data
      }
    },
    bannerOpen(url) {
      window.open(url)
    },
    async getExposureList() {
      const { status, data } = await this.$api.getExposureList({ type: 0 });
      if (status === 200) {
        const { records } = data;
        this.exposureList = records;
      }
    },
    creditOpen() {
      this.$alert('请联系客服查询征信', '征信速查', {
        confirmButtonText: '确定',
        // callback: action => {
        //   this.$message({
        //     type: 'info',
        //     message: `action: ${action}`
        //   });
        // }
      });
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/style/var.scss";

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.m-t {
  margin-top: 10px;
}

.home {
  &-t {
    display: flex;

    &-l {
      width: 74%;
      margin-right: 14px;
      flex-shrink: 0;
    }

    &-r {
      width: calc(26% - 12px);
      flex-shrink: 0;

      .exposure {
        height: 120px;
        background-image: url(~@/static/image/home_exposure.png);
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: center;
        border-radius: 4px;
      }
    }
  }
}

.ElectronicDocuments {
  height: 196px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding-left: 9.42%;
  background-image: url(~@/static/image/home_bg_1.png);
  background-size: 44% 196px;
  background-position-x: 238px;
  background-repeat: no-repeat;
  border: 1px solid #fff;
  position: relative;

  &-l {
    .nickName {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #262626;
      line-height: 28px;
      margin-top: 21px;
    }

    .company {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-top: 12px;
    }

    .info {
      display: flex;

      li {
        // width: 146px;
        width: 13.75%;
        border-right: 1px solid #e3e3e3;
        margin-top: 39px;
        margin-left: 3.75%;
        font-size: 14px;

        &:last-child {
          border: none;
        }

        &:first-child {
          margin-left: 0px;
        }

        span {
          margin-left: 5px;
        }

        &:hover {
          color: #0062ff;
          cursor: pointer;
        }
      }
    }
  }

  &-r {
    // background: #f5f9ff;
    height: 148px;
    width: 25%;
    position: absolute;
    right: 24px;
    top: 24px;
    // border: dashed 1px #66a1ff;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      width: 110px;
      line-height: 40px;
      background: #0062ff;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}

.article {

  // 370
  &-item {
    display: flex;
    height: 30px;
    align-items: center;

    .title {
      // width: 270px;
      flex: 1;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      /* for Opera */
      cursor: pointer;

      &:hover {
        color: #5c6e87;
      }
    }

    .icon {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      .top {
        background: #ff8d1a;
        font-size: 12px;
        color: #fff;
        padding: 4px 6px;
        border-radius: 4px;
        transform: scale(0.8);
      }
    }

    .date {
      width: 50px;
      font-size: 12px;
      color: #8590a6;
    }
  }
}

.sectorization {
  display: flex;
  justify-content: space-between;

  .image {
    width: 49%;
    height: 80px;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    background-color: #ffffff;
    overflow: hidden;
    color: #303133;
    transition: 0.3s;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &:hover {
      filter: Alpha(Opacity=60);
      opacity: 0.8;
    }
  }

  & :nth-child(1) {
    background-image: url(~@/static/image/ffsy.jpg);
    background-color: #cd332b;
  }

  & :nth-child(2) {
    background-image: url(~@/static/image/zxsc.jpg);
  }
}

.instituteJustice {
  &-item {
    display: flex;
    height: 104px;
    padding: 16px 0px;
    cursor: pointer;

    .content {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(60% - 12px);

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; // 控制多行的行数
        -webkit-box-orient: vertical;

        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        line-height: 22px;
      }

      .viewCount {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
      }
    }

    &:hover {
      .title {
        color: #0062ff;
      }
    }
  }

  .mask {
    height: 40px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    background-image: url(~@/static/image/home_bg_3.png);
    left: 0px;
  }
}

.ExpenseCenter {
  .el-card__body {
    // background: #f66;
    background-image: url(~@/static/image/home_bg_2.png);
    background-size: auto 142px;
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  p {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    /* for Opera */
  }

  i {
    font-size: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #262626;
    line-height: 56px;
  }

  .btn {
    width: 110px;
    border-radius: 4px;
    border: 1px solid #0062ff;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0062ff;
    line-height: 40px;
    text-align: center;
    margin-top: 6px;
    cursor: pointer;
  }
}

.newHome {
  &-title {
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
    height: 56px;

    p {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
    }

    span {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #999999;
      cursor: pointer;
    }
  }

  .panel {
    height: 482px;
    background-image: url(~@/static/image/home_bg_4.png);
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 160px;
    position: relative;

    .static {
      position: absolute;
      width: 64px;
      height: 80px;

      right: 0;
      top: 0;
    }

    .error {
      background-image: url(~@/static/image/home_bg_5.png);
    }

    .success {
      background-image: url(~@/static/image/home_bg_6.png);
    }

    &-top {
      margin-top: 10px;
      padding: 20px;
      display: flex;
      align-items: center;

      &-right {
        margin-left: 16px;

        .username {
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: #262626;
          display: flex;
          align-items: center;

          .icon {
            width: 60px;
            height: 20px;
            margin-left: 6px;
          }

          .error {
            background-image: url(~@/static/image/weirenzheng.png);
            background-size: 100%;
          }

          .success {
            background-image: url(~@/static/image/yirenzheng.png);
            background-size: 100%;
          }
        }

        .phone {
          font-size: 14px;
          line-height: 21px;
          color: #999999;
          margin-top: 4px;
        }
      }
    }

    &-bottom {
      // margin-top: 26px;
      margin-top: 70px;
      padding: 0 20px;

      .cell {
        height: 70px;

        background: #f7faff;
        border-radius: 4px;
        background-image: url(~@/static/image/home_cell.png);
        // background-size: 40px 40px;
        background-position: 10px 40px;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        padding: 0 20px;
        justify-content: space-between;
        margin-bottom: 16px;

        &:hover {
          .icon {
            transform: rotateY(180deg);
          }
        }

        &-l {
          display: flex;
          align-items: center;

          .icon {
            width: 30px;
            height: 30px;
            transition: all 1s;

            &-0 {
              background-image: url(~@/static/image/home_cell/1.png);
            }

            &-1 {
              background-image: url(~@/static/image/home_cell/2.png);
            }

            &-2 {
              background-image: url(~@/static/image/home_cell/3.png);
            }

            &-3 {
              background-image: url(~@/static/image/home_cell/4.png);
            }
          }

          .title {
            margin-left: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
        }

        &-r {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          /* 字体/999999 */

          color: #999999;
        }
      }
    }
  }

  .electronicDocuments {
    height: 174px;
    background-image: url(~@/static/image/home_bg_2.png);
    background-size: auto 100px;
    background-repeat: no-repeat;
    background-position: right bottom;

    &-content {
      display: flex;
      align-items: center;

      .item {
        width: calc((100% - 3px) / 4);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 98px;

        .value {
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 45px;
          color: #000000;
        }

        .label {
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #999999;
        }
      }

      .solid {
        width: 1px;
        height: 26px;
        background: #eeeeee;
      }
    }
  }

  .expenseCenter {
    height: 292px;

    &-content {
      padding: 0 20px;

      .item {
        height: 100px;
        background: linear-gradient(180deg,
            #f7f8f9 0%,
            rgba(247, 248, 249, 0) 100%);
        border-radius: 4px;
        padding: 16px;

        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .label {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 21px;
            color: #262626;

            img {
              margin-left: 4px;
            }
          }

          .btn {
            font-family: "Source Han Sans CN";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #0062ff;
            cursor: pointer;
          }
        }

        .value {
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 45px;
          color: #262626;
          margin-top: 4px;
        }
      }
    }
  }

  .notice {
    height: 374px;

    &-content {
      padding: 0 20px;

      .item {
        height: 76px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-t {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &-l {
            display: flex;
            align-items: center;
            width: calc(100% - 36px);

            i {
              color: #cccccc;
            }

            p {
              font-family: "Source Han Sans CN";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #262626;

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              // width: calc(100% - 66px);
            }

            .icon {
              background: rgba($color: #ff8412, $alpha: 0.1);
              border-radius: 2px;
              font-family: "Source Han Sans CN";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #ff8412;
              text-align: center;
              width: 36px;
              height: 20px;
              margin: 0 10px;
              flex-shrink: 0;
            }
          }

          .time {
            font-family: "Source Han Sans CN";
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #999999;
            width: 40px;
          }
        }

        &-b {
          text-indent: 1em;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          margin-top: 6px;
          font-family: "Source Han Sans CN";
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #999999;
        }
      }
    }
  }

  .exposure {
    height: 374px;

    @keyframes rowup {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }

      100% {
        -webkit-transform: translate3d(0, -307px, 0);
        transform: translate3d(0, -307px, 0);
      }
    }

    &-content {
      padding: 0 20px;
      overflow: hidden;
      height: 318px;
      position: relative;

      .mask {
        position: absolute;
        width: 100%;
        height: 100px;
        background: linear-gradient(180deg,
            rgba(255, 255, 255, 0) 0%,
            #ffffff 100%);
        bottom: 0px;
        left: 0px;
      }

      .item {
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        -webkit-animation: 20s rowup linear infinite normal;
        animation: 20s rowup linear infinite normal;

        &-l {
          display: flex;
          align-items: center;

          .time {
            font-family: "Source Han Sans CN";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #666666;
          }

          .name {
            margin-left: 30px;
            font-family: "Source Han Sans CN";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;

            color: #ff8412;
          }
        }

        .idNumber {
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #262626;
        }
      }
    }
  }
}
</style>
