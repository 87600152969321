/*
* @Author: 曹俊杰
* @Date: 2022-04-28 19:39:02
* @Module: 二维码弹出框
*/
<template>
    <div class="toastDialog">
        <el-dialog destroy-on-close :visible.sync="showToastDialog" @close="close" custom-class="content" width="100"
            :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
            <div style="position: absolute;top: 10px;right: 10px;" @click="close">
                <el-button type="text">{{ second?`阅读${second}秒可关闭`:'关闭' }}</el-button>
            </div>
            <img src="@/static/1.png" alt="">
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from "vuex";
import second from "@/mixins/second.js"
export default {
    mixins: [second],
    data() {
        return {
            url: ''
        };
    },
    computed: {
        showToastDialog: {
            get() {
                return this.$store.state.showToastDialog
            },
            set(res) {
                this.$store.commit('showToastDialog', res)
            }
        }
    },
    watch: {
        showToastDialog: {
            handler(newVal) {
                console.log(newVal)
               if(newVal){
                this.secondrun()
               }
            },
            immediate: true // 初始化时立即执行
        }
    },
    mounted() { },
    methods: {
        show(url) {
            this.$store.commit('showToastDialog', true)
        },
        hidden() {
            this.$store.commit('showToastDialog', false)
        },
        close() {
            console.log(this.second)
            if(this.second>0){
                return
            }
           this.hidden()
        }
    },
};
</script>
<style lang='scss' scoped>
.toastDialog {
    &/deep/ .el-dialog__header {
        padding: 0;
    }

    &/deep/ .el-dialog__body {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;

        .text {
            margin-top: 20px;

            span:nth-child(2) {
                color: #0062ff;
            }
        }
    }
}
</style>
