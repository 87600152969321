/* 
 * @Author: 曹俊杰 
 * @Date: 2022-10-25 16:08:47
 * @Module: 查小号加速表单
 */
 <template>
  <div class="searchSpeedupForm">
    <el-dialog title="加速等级设置" :visible.sync="dialogVisible" width="500px">
      <div>
        <div class="top">
          <span class="top-l">选择加速等级</span>
          <span class="top-r">
            （加速可增加曝光，提高找到小号概率。升级仅需补差价）
          </span>
        </div>
        <div class="select">
          <div v-for="item in select" :key="item.level" class="select-item pointer" :class="{'active':item.level===form.level}" @click="()=>switchActive(item)">
            <div class="select-item-tit">
              <i :class="item.icon" style="color:#0d72ff;font-size:12px;margin-right:5px;"></i>
              <span>
                {{item.tit}}
              </span>

            </div>
            <div class="select-item-sub">{{item.sub}}</div>
          </div>
        </div>
        <!-- <div style="padding:20px;">
          <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
            <el-form-item label="加速等级" prop="level" :rules="{ required: true, message: '加速等级不能为空'}">
              <el-select v-model="form.level" placeholder="请选择加速等级" clearable style="width:100%;">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" v-show="level<item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div> -->
        <div class="content">
          <div></div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <div class="gold">
          <i class="gold-icon"></i>
          消耗卡券：
          <span style="font-size: 18px;color: #0062FF;">{{sum}}</span>
        </div>
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submit" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 <script>
import { mapState } from "vuex";
export default {
  components: {
  },
  data () {
    return {
      dialogVisible: false,
      form: {
        level: 0,
        searchUuid: null
      },
      sum: 0,
      select: [
        {
          level: 0,
          tit: '不加速',
          sub: '',
          gold: 0,
          icon: ''
        },
        {
          level: 1,
          tit: '快速',
          sub: '消耗5卡券',
          gold: 5,
          icon: 'el-icon-ali-Union'
        },
        {
          level: 2,
          tit: '高速',
          sub: '消耗10卡券',
          gold: 10,
          icon: 'el-icon-ali-Vector'
        },
        {
          level: 3,
          tit: '光速',
          sub: '消耗20卡券',
          gold: 20,
          icon: 'el-icon-ali-Vecto2r'
        }
      ],
      level: 0
    };
  },
  mounted () { },
  computed: {
    ...mapState({
      beans: state => state.user.beans,
    })
  },
  methods: {
    show ({ uuid, level }) {
      this.dialogVisible = true;
      this.form.searchUuid = uuid;
      this.form.level = level;
      this.level = level
    },
    submit () {
      //  系统不可降级
      if (this.level < this.form.level) {
        // 卡券余额是否充足
        if (this.sum > this.beans) {
          this.$alert('<p><span>您的真好签账户卡券不足，</span><a class="primary pointer" >前去充值</a></p>', '卡券不足', {
            dangerouslyUseHTMLString: true,
            showCancelButton: true,
            confirmButtonText: '充值',
            type: 'warning',
          }).then(() => {
            // this.$router.push({ name: 'topUp', params: { type: 'BEAN' } })
            this.$store.commit('showToastDialog', true)
          })
        } else {
          this.putSearchSpeedup()
        }
      } else if (this.level == this.form.level) {
        this.dialogVisible = false;
      } else {
        this.$message({
          type: "warning",
          message: "加速不可进行降级"
        })
      }
    },
    switchActive ({ level, gold }) {
      const index = this.select.findIndex(item => item.level == this.level);
      const oldGold = this.select[index].gold;
      const buy = gold - oldGold
      if (buy < 0) {
        this.$message({
          type: "warning",
          message: "加速不可进行降级"
        })
      } else {
        this.sum = buy
        this.form.level = level;
      }

    },
    async putSearchSpeedup () {
      const { status, info } = await this.$api.putSearchSpeedup(this.form)
      if (status === 200) {
        this.$message({
          message: info,
          type: 'success'
        })
        this.$emit('success');
        this.dialogVisible = false;
        this.$store.dispatch("user/getCompanyBalance")
      } else {
        this.$message({
          message: info,
          type: 'error'
        })
      }


    }
  },
};
 </script>
 <style lang='scss' scoped>
.searchSpeedupForm {
  &/deep/.el-dialog {
    &__header {
      padding: 20px;
      border-bottom: 1px solid #eeeeee;
    }
    &__body {
      padding-top: 10px;
    }
    &__footer {
      border-top: 1px solid #eeeeee;
      padding-top: 20px;
    }
    .gold {
      float: left;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height */

      color: #262626;
      &-icon {
        display: table-cell;
        vertical-align: middle;
        display: inline-block;
        width: 14px;
        height: 14px;
        background-image: url(~@/static/image/gold.png);
      }
    }
  }
  .top {
    &-l {
      font-weight: 400;
      font-size: 14px;
      line-height: 36px;
      /* identical to box height */
      color: #262626;
    }
    &-r {
      font-weight: 400;
      font-size: 14px;
      line-height: 36px;
      /* identical to box height */
      color: #999999;
    }
  }
  .select {
    display: flex;

    &-item {
      height: 72px;
      border: 1px solid #e3e3e3;
      border-radius: 2px;
      width: 22%;
      margin-right: 4%;
      &:last-child {
        margin-right: 0;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &-tit {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #0062ff;
      }
      &-sub {
        margin-top: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
      }
    }
    .active {
      border: 1px solid #0062ff;
      box-shadow: 0px 4px 6px rgba(0, 98, 255, 0.1);
      border-radius: 2px;
    }
  }
}
</style>