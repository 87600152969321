import Vue from 'vue'
import Vuex from 'vuex'
import createVuexAlong from 'vuex-along'
import user from './user'
import help from './help'
import electronic from './electronic'
import documents from './documents'
import userLog from './userLog'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		showToastDialog: true
	},
	mutations: {
		showToastDialog(state, payload) {
			state.showToastDialog = payload;
		},
	},
	actions: {
	},
	modules: {
		user,
		electronic,
		help,
		documents,
		userLog
	},
	// vuex 状态储存   文档-https://github.com/boenfu/vuex-along#readme
	plugins: [createVuexAlong({
		local: {
			list: ["user", "electronic", "userLog"],
		}
	})]
})
