/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-06 21:54:59
 * @Module: 充值中心
 */
 <template>
  <div class="VoucherCenter">
    <div class="VoucherCenter-top border">
      <div class="title">
        <span>账户名称：</span>
        <span style="color:#262626">{{company}}</span>
      </div>
      <div class="content">
        <div class="item" style="margin-right:20px;">
          <div class="icon jb"></div>
          <div class="c">
            <div class="label">账户金币</div>
            <div class="value">{{balance|priceFormat  }}</div>
          </div>
          <div class="r pointer" @click="$store.commit('showToastDialog', true)">金币充值</div>
        </div>
        <div class="item">
          <div class="icon kq"></div>
          <div class="c">
            <div class="label">账户卡券</div>
            <div class="value" style="color: #0062FF;">{{beans|priceFormat }}</div>
          </div>
          <div class="r pointer" @click="$store.commit('showToastDialog', true)">卡券充值</div>
        </div>
      </div>
    </div>
    <div class="tabs border">
      <div class="tabs-item pointer" :class="{active:$route.name=='recharge'}" @click="$router.push({path:'/expenseCenter/recharge'})">
        充值记录
      </div>
      <div class="tabs-item pointer" :class="{active:$route.name=='expense'}" @click="$router.push({path:'/expenseCenter/expense'})">
        消费记录
      </div>
      <div class="solid " :class="$route.name=='recharge'?'solid-ac1':'solid-ac2'"></div>
    </div>
    <router-view />
  </div>
</template>
 <script>
import { mapState } from "vuex";
export default {
  components: {},
  data () {
    return {};
  },
  computed: {
    ...mapState({
      balance: state => state.user.balance,
      company: state => state.user.user.company,
      beans: state => state.user.beans,
    })
  },
  mounted () {
    this.$store.dispatch('user/getCompanyBalance');
  },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.VoucherCenter {
  &-top {
    padding-bottom: 20px;
    .title {
      line-height: 56px;
      padding: 0 20px;

      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
    }
    .content {
      padding: 0 20px;
      display: flex;
      .item {
        height: 106px;
        background: linear-gradient(180deg, #f7f8f9 0%, #f7f8f9 100%);
        border-radius: 4px;
        width: calc((100% - 20px) / 2);
        display: flex;
        align-items: center;
        padding: 0 20px;
        .c {
          margin-left: 16px;
          .label {
            font-family: "Source Han Sans CN";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #262626;
          }
          .value {
            font-family: "Source Han Sans CN";
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 45px;
            color: #262626;
          }
        }
        .r {
          margin-left: auto;
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #0062ff;
        }
        .icon {
          width: 60px;
          height: 60px;
        }
        .jb {
          background-image: url(~@/static/image/voucherCenter_jb.png);
        }
        .kq {
          background-image: url(~@/static/image/voucherCenter_kq.png);
        }
      }
    }
  }
  .tabs {
    position: relative;
    display: flex;
    margin-top: 16px;
    border-bottom: 1px solid #eee;
    &-item {
      width: 104px;
      line-height: 56px;
      text-align: center;
    }
    .solid {
      width: 30px;
      height: 2px;
      background: #0062ff;
      border-radius: 2px;
      position: absolute;
      bottom: -1px;
      transition: 0.5s all;
      &-ac1 {
        left: 37px;
      }
      &-ac2 {
        left: 141px;
      }
    }
    .active {
      color: #0062ff;
    }
  }
}
</style>