/* 
 * @Author: 曹俊杰 
 * @Date: 2022-10-20 19:56:05
 * @Module:查询小号表单 
 */
 <template>
  <div>

    <el-card shadow="never" :body-style="{position:'relative'}">
      <div slot="header" style="position: relative;">
        <hdq-section title="发布线索" more-text="返回" @more="$router.go(-1)" />
      </div>
      <el-row :gutter="20">
        <el-form :model="form" label-position="top" ref="form">
          <el-col :span="12">
            <el-form-item label="主播性别" prop="gender" :rules="{ required: true, message: '请选择主播性别'}">
              <el-select v-model="form.gender" placeholder="请选择主播性别" style="width: 100%">
                <el-option key="1" label="男" :value="1" />
                <el-option key="2" label="女" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="主播年龄" prop="age" :rules="{ required: true, message: '请输入主播年龄'}">
              <el-input v-model.number="form.age" placeholder="请输入主播年龄"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height:112px;">
            <el-form-item label="城市（每多选1个城市需多消耗一个卡券）" prop="citys" :rules="{ required: true, message: '请选择主播小号可能在哪些城市直播'}">
              <citys v-model="form.citys" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="平台（每多选1个平台需多消耗一个卡券）" prop="platforms" :rules="{ required: true, message: '请选择平台'}">
              <platforms v-model="form.platforms" placeholder="请选择主播小号可能在哪些平台直播" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="常用昵称" prop="nickname" :rules="{ required: true, message: '请输入主播常用昵称'}">
              <el-input v-model="form.nickname" placeholder="请输入主播常用昵称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话（非主播电话，查询成功后会联系此号码与您联系）" prop="phone" :rules="{ required: true, message: '请输入您的联系电话'}">
              <el-input v-model="form.phone" placeholder="查询成功会用此号码与您联系"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="大号直播间ID" prop="originId" :rules="{ required: true, message: '请输入大号直播间ID'}">
              <el-input v-model="form.originId" placeholder="请输入主播大号直播间ID"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="大号所在平台" prop="originPlatform" :rules="{ required: true, message: '请选择大号所在平台'}">
              <platforms v-model="form.originPlatform" placeholder="请选择大号所在平台" :multiple="false" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="主播情况（比如停播情况、流水情况、大号在播等）" prop="note" :rules="{ required: true, message: '请输入备注'}">
              <el-input v-model="form.note" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请简单介绍一下主播的情况"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="主播图片（尽量用直播截图正脸，朋友圈照片、生活照等均会降低找到小号的几率！）" prop="images" :rules="{ required: true, message: '请选择图片'}">
              <images v-model="form.images" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="辅助图片（参考图片，仅供参考,可上传3张，为主要图片做辅助参考）" prop="images">
              <images v-model="form.assistimages" :limit="3" />
            </el-form-item>
          </el-col> -->
        </el-form>

      </el-row>
      <el-divider></el-divider>
      <div class="message">
        <p class="t">默认查询时长：30天 <span style="color:#999;">（城市{{form.citys.length}}个、平台{{form.platforms.length}}个）</span></p>
        <p class="b"> <span>需消耗卡券：</span><i class="gold-icon" /><span style="color: #0062FF;font-weight: 500;
font-size: 18px;">{{sum}}</span></p>
      </div>
      <el-button type="primary" style="width:110px" @click="submit">确认</el-button>
    </el-card>
  </div>
</template>
 <script>
import citys from "@/views/Search/components/citys"
import platforms from "@/views/Search/components/platforms"
import images from "@/views/Search/components/images"
import { mapState } from "vuex";
export default {
  components: {
    citys,
    platforms,
    images
  },
  data () {
    return {
      form: {
        gender: null,
        age: null,
        citys: [],
        platforms: [],
        images: [],
        nickname: null,
        note: null,
        phone: null,
        originId: null,
        originPlatform: null,
        assistimages: []
      }
    };
  },
  computed: {
    ...mapState({
      phone: state => state.user.user.phone,
      beans: state => state.user.beans,
    }),
    sum () {
      if (this.form.platforms.length && this.form.citys.length) {
        return 3 + (this.form.platforms.length * 1 - 1) + (this.form.citys.length * 1 - 1)
      } else {
        return 3
      }
    }
  },
  mounted () {
    this.form.phone = this.phone
  },
  methods: {
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.sum > this.beans) {
            this.$alert('<p><span>您的真好签账户卡券不足，</span><a class="primary pointer" >前去充值</a></p>', '卡券不足', {
              dangerouslyUseHTMLString: true,
              showCancelButton: true,
              confirmButtonText: '充值',
              type: 'warning',
            }).then(() => {
              // this.$router.push({ name: 'topUp', params: { type: 'BEAN' } })
              this.$store.commit('showToastDialog', true)
            })
          } else {
            this.postSearch()
          }
        } else {
          return false
        }
      })
    },
    closed () {
      this.form = {
        gender: null,
        age: null,
        citys: [],
        platforms: [],
        images: [],
        nickname: null,
        note: null,
        phone: null,
        originId: null,
        originPlatform: null,
        assistimages: []
      };
    },
    async postSearch () {
      try {
        await this.$confirm(`录入一个查询信息，收费${this.sum}卡券，有效期1个月，到期自动扣卡券续费。(基础3个豆一个素材单个平台，单个城市，追加一个平台需追加一个豆，追加一个城市需追加一个豆)`, '消费提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const params = {
          ...this.form,
          platforms: this.form.platforms.join(),
          images: [...this.form.images, ...this.form.assistimages].filter(item => {
            if (item.response.code == 1) {
              return true
            } else {
              this.$message({
                message: item.response.msg,
                type: 'error'
              })
              return false
            }
          }).map(item => item.response.data.fullurl).join(),
          // citys: this.form.citys.map(item => item.join('/')).join()
          citys: this.form.citys.map(item => item[1]).join()
        }
        const { status, data, info } = await this.$api.postSearch(params)
        if (status === 200) {
          this.$message({
            message: info,
            type: 'success'
          })
          this.closed()
        } else {
          this.$message({
            message: info,
            type: 'error'
          })
        }
      } catch (res) {
        if (res === 'cancel') {
          this.$message({
            message: '取消查询'
          })
        }
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.message {
  padding-bottom: 23px;
  .t {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
  .b {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    margin-top: 16px;
  }
}
.gold-icon {
  display: table-cell;
  vertical-align: middle;
  display: inline-block;
  width: 19px;
  height: 17px;
  background-image: url(~@/static/image/icon_dianquan.png);
  margin-right: 5px;
}
</style>